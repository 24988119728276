



















import { Component, Vue } from 'vue-property-decorator';

import WrapperInstability from '@/components/WrapperInstability/WrapperInstability.vue';

import ErrorPage from '@/assets/imgs/maintenance/ErrorPage.vue';

import instability from '@/mixins/Instability';

@Component({
  mixins: [instability],
  components: {
    WrapperInstability,
    ErrorPage
  }
})
export default class NotFound extends Vue {
  redirectToDashboard() {
    this.$router.push({
      name: 'Dashboard'
    });
  }
}
